import API from './API';
import { CLASS_TYPES } from 'common/enums/class.enum';
import { REPORT_VIEW_RESULT } from 'pages/reports/enum';
import { ExportFamilyDetailPrams } from 'pages/reports/type';

const REPORT_ENDPOINT = '/report';

export const getStudentBookingSummaryReport = (
  dateFrom: number,
  dateTo: number,
  locationId: string,
  programType: CLASS_TYPES,
  levelIds: Array<string>
) => {
  return API.get(`${REPORT_ENDPOINT}/student-booking-summary`, {
    dateFrom,
    dateTo,
    locationId,
    programType,
    levelIds
  });
};

export const getTimetableByTeacherReport = (
  dateFrom: number,
  dateTo: number,
  timeFrom: number,
  timeTo: number,
  locationId: string,
  programType: CLASS_TYPES,
  instructorIds: Array<string>
) => {
  return API.get(`${REPORT_ENDPOINT}/time-table-by-teacher`, {
    dateFrom,
    dateTo,
    timeFrom,
    timeTo,
    locationId,
    programType,
    instructorIds
  });
};

export const generateTimetableByTeacherPDF = (
  dateFrom: number,
  dateTo: number,
  timeFrom: number,
  timeTo: number,
  locationId: string,
  programType: CLASS_TYPES,
  instructorIds: Array<string>,
  timezone: string
) => {
  return API.post(
    `${REPORT_ENDPOINT}/time-table-by-teacher/excel`,
    {
      body: {
        dateFrom,
        dateTo,
        timeFrom,
        timeTo,
        locationId,
        programType,
        instructorIds,
        timezone
      }
    },
    {},
    undefined,
    {
      responseType: 'blob'
    }
  );
};

export const getStudentOutstandingReport = (
  pageIndex: number,
  pageSize: number,
  dateFrom: number,
  dateTo: number,
  programType: string,
  lessonDay: string
) => {
  return API.get(`${REPORT_ENDPOINT}/student-outstanding`, {
    page: pageIndex,
    limit: pageSize,
    dateFrom,
    dateTo,
    programType,
    lessonDay
  });
};
export const getCreditAdjustmentReport = (
  pageIndex: number,
  pageSize: number,
  dateFrom: number,
  dateTo: number,
  creditTypes: string[],
  locationId: string
) => {
  return API.get(`${REPORT_ENDPOINT}/credit-adjustment-report`, {
    page: pageIndex,
    limit: pageSize,
    dateFrom,
    dateTo,
    refundCreditTypes: creditTypes.join(','),
    locationId
  });
};

export const getPeriodSummaryReport = (
  locationId: string,
  dateFrom: number,
  dateTo: number,
  programType: CLASS_TYPES,
  paymentType: string,
  viewResult: REPORT_VIEW_RESULT
) => {
  return API.get(`${REPORT_ENDPOINT}/period-summary-report`, {
    locationId,
    dateFrom,
    dateTo,
    programType,
    paymentType,
    viewResult
  });
};

export const getMemberReport = (params: {
  locationId: string;
  dateFrom: number;
  dateTo: number;
  programType: CLASS_TYPES;
  paymentType: string;
  filterBy?: string;
  page?: number;
  limit?: number;
}) => {
  if (!params.filterBy) delete params.filterBy;

  return API.get(`${REPORT_ENDPOINT}/member-report`, params);
};
export const exportMemberReport = (params: {
  locationId: string;
  dateFrom: number;
  dateTo: number;
  programType: CLASS_TYPES;
  paymentType: string;
  filterBy?: string;
}) => {
  if (!params.filterBy) delete params.filterBy;

  return API.get(
    `${REPORT_ENDPOINT}/member-report/excel`,
    params,
    undefined,
    undefined,
    {
      responseType: 'blob'
    }
  );
};

export const getGstReport = (params: {
  locationIds: string[];
  dateFrom: number;
  dateTo: number;
}) => {
  return API.post(`${REPORT_ENDPOINT}/gst-report`, { body: params });
};

export const getPdfGstReport = (params: {
  locationIds: string[];
  dateFrom: number;
  dateTo: number;
}) => {
  return API.post(
    `${REPORT_ENDPOINT}/gst-report/pdf`,
    { body: params },
    {
      responseType: 'blob'
    }
  );
};

export const getPdfGstReportBreakdown = (params: {
  locationIds: string[];
  dateFrom: number;
  dateTo: number;
}) => {
  return API.post(
    `${REPORT_ENDPOINT}/gst-report-breakdown/pdf`,
    { body: params },
    {
      responseType: 'blob'
    }
  );
};

export const getExportFamilyDetailsReport = (
  params: ExportFamilyDetailPrams
) => {
  return API.get(`${REPORT_ENDPOINT}/family-report`, params);
};

export const getNonAttendanceReport = (
  page: number,
  limit: number,
  locationId: string,
  dateFrom: number,
  dateTo: number,
  programType: CLASS_TYPES,
  numberOfConsecutive: number,
  levelIds: string
) => {
  return API.get(`${REPORT_ENDPOINT}/non-attendance-report`, {
    page,
    limit,
    locationId,
    dateFrom,
    dateTo,
    programType,
    numberOfConsecutive,
    levelIds
  });
};

export const getGraduatedAndNotMovedReport = (
  page: number,
  limit: number,
  locationId: string
) => {
  return API.get(`${REPORT_ENDPOINT}/graduation-report`, {
    page,
    limit,
    locationId
  });
};

export const getDateOfLastAssessmentReport = (
  pageIndex: number,
  pageSize: number,
  dateFrom: number,
  dateTo: number,
  programType: string,
  lessonDay: string,
  levelIds: string[],
  locationId?: string
) => {
  return API.get(`${REPORT_ENDPOINT}/date-of-last-assessment-report`, {
    page: pageIndex,
    limit: pageSize,
    dateFrom,
    dateTo,
    programType,
    lessonDay: lessonDay || undefined,
    levelIds,
    locationId
  });
};
export const getAbandonedCartReport = (
  page: number,
  limit: number,
  locationId: string
) => {
  return API.get(`${REPORT_ENDPOINT}/abandoned-cart-report`, {
    page,
    limit,
    locationId
  });
};

export const getAssessmentOutcomeReport = (
  page: number,
  limit: number,
  locationId: string,
  dateFrom: number,
  dateTo: number,
  programType: CLASS_TYPES,
  assessmentResults: Array<string>,
  levelIds: Array<string>,
  lessonDay?: string
) => {
  const params = {
    page,
    limit,
    locationId,
    dateFrom,
    dateTo,
    programType,
    assessmentResults,
    levelIds,
    lessonDay
  };
  if (!lessonDay) delete params.lessonDay;
  return API.get(`${REPORT_ENDPOINT}/assessment-outcome-report`, params);
};

export const getDiscountedEnrolmentReport = (
  page: number,
  limit: number,
  locationId: string,
  dateFrom: number,
  dateTo: number
) => {
  return API.get(`${REPORT_ENDPOINT}/discount-enrolment-report`, {
    page,
    limit,
    locationId,
    dateFrom,
    dateTo
  });
};
