import React, { useCallback, useEffect, useState } from 'react';
import './desktop.scss';
import { createColumnHelper } from '@tanstack/react-table';
import {
  IStudent,
  IStudentEmergencyContact,
  IStudentListResponsiblePerson
} from 'common/interfaces/student.interface';
import {
  formatData,
  formatDate,
  formatSecretPhoneNumber
} from 'common/helpers/dataFormat.helper';
import ActionPopper from './ActionPopper';
import { useNavigate } from 'react-router-dom';
import AppTable from 'common/components/AppTable';
import AppToggle from 'common/components/AppToggle';
import { IRollCallDetail } from 'common/interfaces/rollCall.interface';
import {
  getStudentsByRollCall,
  rollCallAttended,
  rollCallCheckIn,
  rollCallEvacuated
} from 'services/rollCalls.service';
import { useToast } from 'context/ToastContext';
import UndoActionRollCall from './UndoActionRollCall';
import PermissionWrapper from 'components/PermissionWrapper';
import { PERMISSION } from 'common/enums/permission.enum';
import dayjs from 'dayjs';
import {
  MINUTES_CHECK_IN_AFTER_CLASS,
  MINUTES_CHECK_IN_BEFORE_CLASS
} from 'common/constants/index';

interface Props {
  data: IRollCallDetail;
  isEmergency: boolean;
}
const RollCallForClassSession: React.FC<Props> = ({
  data,
  isEmergency
}: Props) => {
  const { _id } = data;
  const navigate = useNavigate();
  const toast = useToast();
  const columnHelper = createColumnHelper<IStudent>();
  const [students, setStudents] = useState<IStudent[]>([]);
  const [selectedStudentId, setSelectedStudentId] = useState<string>('');
  const [action, setAction] = useState<string>('');
  const [showUndoModal, setShowUndoModal] = useState<boolean>(false);

  useEffect(() => {
    fetchStudents();
    // eslint-disable-next-line
  }, [_id]);

  // eslint-disable-next-line
  const allowToCheckIn = () => {
    // only allow check in before 90 minutes and after 90 mins
    return (
      dayjs().diff(dayjs(data.startTime), 'minute') >=
        -MINUTES_CHECK_IN_BEFORE_CLASS &&
      dayjs().diff(dayjs(data.endTime), 'minute') <=
        MINUTES_CHECK_IN_AFTER_CLASS
    );
  };
  const handleCheckIn = useCallback(
    async (id: string) => {
      // if (!allowToCheckIn()) {
      //   toast.error(
      //     `The check-in time is between ${formatTime(
      //       dayjs(data.startTime).subtract(
      //         MINUTES_CHECK_IN_BEFORE_CLASS,
      //         'minutes'
      //       )
      //     )} and ${formatTime(dayjs(data.endTime))} on ${formatDate(
      //       data.startTime
      //     )}`
      //   );
      //   return;
      // }
      try {
        await rollCallCheckIn({ studentId: id, rollCallId: _id });
      } catch (error: any) {
        toast.error(
          error.response.data.message || 'Fail to check in for this student'
        );
      } finally {
        fetchStudents();
      }
    },
    // eslint-disable-next-line
    [_id]
  );

  const handleAttended = useCallback(
    async (id: string) => {
      // if (!allowToCheckIn()) {
      //   toast.error(
      //     `The time to update attended status is between ${dayjs(data.startTime)
      //       .subtract(MINUTES_CHECK_IN_BEFORE_CLASS, 'minutes')
      //       .format('HH:mm')} and ${formatTime(
      //       dayjs(data.endTime)
      //     )} on ${formatDate(data.startTime)}`
      //   );
      //   return;
      // }
      try {
        await rollCallAttended({ studentId: id, rollCallId: _id });
      } catch (error: any) {
        toast.error(
          error.response.data.message ||
            'Fail to update attended status for this student'
        );
      } finally {
        fetchStudents();
      }
    },
    // eslint-disable-next-line
    [_id]
  );

  const handleEvacuated = useCallback(
    async (id: string) => {
      // if (!allowToCheckIn()) {
      //   toast.error(
      //     `The time to update evacuated status is between ${dayjs(
      //       data.startTime
      //     )
      //       .subtract(MINUTES_CHECK_IN_BEFORE_CLASS, 'minutes')
      //       .format('HH:mm')} and ${formatTime(
      //       dayjs(data.endTime)
      //     )} on ${formatDate(data.startTime)}`
      //   );
      //   return;
      // }
      try {
        await rollCallEvacuated({ studentId: id, rollCallId: _id });
      } catch (error: any) {
        toast.error(
          error.response.data.message ||
            'Fail to update evacuated status for this student'
        );
      } finally {
        fetchStudents();
      }
    },
    // eslint-disable-next-line
    [_id]
  );

  const onClickEvacuated = useCallback(
    async (item: IStudent) => {
      if (!item.checkin.checkIn) {
        toast.error('This student has not checked in');
        return;
      }
      try {
        await rollCallEvacuated({ studentId: item._id, rollCallId: _id });
      } catch (error: any) {
        toast.error(
          error.response.data.message || 'Fail to evacuated for this student'
        );
      } finally {
        fetchStudents();
      }
    },
    // eslint-disable-next-line
    [_id]
  );

  const columns = [
    columnHelper.accessor('firstName', {
      header: () => <div className="line-clamp-1">SURNAME, NAME</div>,
      cell: (info) =>
        formatData(info.row.original?.lastName) +
        ', ' +
        formatData(info.row.original?.firstName),
      size: 50
    }),
    columnHelper.accessor('listResponsiblePerson', {
      header: () => <span>RP NAME</span>,
      cell: (info) => {
        if (info.row.original?.listResponsiblePerson?.length) {
          const RP = info.row.original?.listResponsiblePerson[0];
          return formatData(RP?.responsiblePersonInfo?.firstName);
        }
        return formatData(false);
      },
      size: 200
    }),
    columnHelper.accessor('createdAt', {
      header: () => <span>Started</span>,
      cell: (info) => {
        return formatDate(info.getValue());
      }
    }),
    columnHelper.accessor('dob', {
      header: () => <span>Age</span>,
      cell: (info) => {
        // Convert the date string to a Date object
        const date = new Date(info.getValue());

        // Get the current date
        const currentDate = new Date();

        // Calculate the number of years and months
        let years = currentDate.getFullYear() - date.getFullYear();
        let months = currentDate.getMonth() - date.getMonth();

        // Adjust the months if the result is negative
        if (months < 0) {
          years -= 1;
          months += 12;
        }
        if (years > 0) {
          return `${years} years ${months} months`;
        } else {
          return `${months} months`;
        }
      }
    }),
    columnHelper.accessor('outstanding', {
      header: () => <span>outstanding</span>,
      cell: (info) => {
        return `$${formatData(info.getValue())}`;
      }
    }),
    columnHelper.accessor('assessmentResults', {
      header: () => <span>Assessment</span>,
      cell: (info) => {
        return (
          <div
            className={`assessmentStatus assessmentStatus__${
              info.getValue()?.[0]?.assessmentStatus
            }`}
          >
            {formatData(
              !!info.getValue()?.[0]?.assessmentStatus
                ? info.getValue()?.[0]?.assessmentStatus?.replaceAll('-', ' ')
                : undefined
            )}
          </div>
        );
      }
    }),
    columnHelper.accessor('checkin.checkIn', {
      header: () => <span>Check in</span>,
      cell: (info) => {
        return (
          <div style={{ textAlign: 'left' }}>
            <PermissionWrapper permission={PERMISSION.CHECK_IN}>
              <AppToggle
                value={info.getValue() || false}
                onChange={() => {
                  if (info.getValue()) {
                    onSelectStudent(info.row.original._id, 'check-in');
                    setShowUndoModal(true);
                  } else {
                    handleCheckIn(info.row.original._id);
                  }
                }}
              />
            </PermissionWrapper>
          </div>
        );
      }
    }),
    columnHelper.accessor('checkin.attended', {
      header: () => <span>Attended</span>,
      cell: (info) => {
        return (
          <div style={{ textAlign: 'left' }}>
            <PermissionWrapper permission={PERMISSION.CHECK_IN}>
              <AppToggle
                value={info.getValue() || false}
                onChange={() => {
                  if (info.getValue()) {
                    onSelectStudent(info.row.original._id, 'attended');
                    setShowUndoModal(true);
                  } else {
                    handleAttended(info.row.original._id);
                  }
                }}
              />
            </PermissionWrapper>
          </div>
        );
      }
    }),
    columnHelper.accessor('_id', {
      header: () => (
        <span style={{ display: 'block', textAlign: 'center' }}>Action</span>
      ),
      cell: (info) => {
        return (
          <div className="buttonGroups">
            <PermissionWrapper permission={PERMISSION.VIEW_DETAIL_STUDENT}>
              <ActionPopper
                onViewStudent={() => handleViewStudent(info.getValue())}
              />
            </PermissionWrapper>
          </div>
        );
      }
    })
  ];

  const emergencyColumn = [
    ...columns.slice(0, 1),

    columnHelper.accessor('listResponsiblePerson', {
      header: () => <span>Rp Number</span>,
      cell: (info) => {
        return (
          <div className="phone-field">
            {info.getValue()?.map((item: IStudentListResponsiblePerson) => {
              return (
                <a href={`tel:${item?.responsiblePersonInfo?.phoneNumber}`}>
                  {formatSecretPhoneNumber(
                    item?.responsiblePersonInfo?.phoneNumber
                  )}
                </a>
              );
            })}
          </div>
        );
      }
    }),
    columnHelper.accessor('emergencyContacts', {
      header: () => <span>Emergency contact number</span>,
      cell: (info) => {
        return (
          <div className="phone-field">
            {info.getValue()?.map((item: IStudentEmergencyContact) => {
              return (
                <a href={`tel:${item?.phoneNumber}`}>
                  {formatSecretPhoneNumber(item?.phoneNumber)}
                </a>
              );
            })}
          </div>
        );
      }
    }),
    ...columns.slice(6, 8),
    columnHelper.accessor('checkin.evacuated', {
      header: () => <span>Evacuated</span>,
      cell: (info) => {
        return (
          <div style={{ textAlign: 'left' }}>
            <PermissionWrapper permission={PERMISSION.CHECK_IN}>
              <AppToggle
                value={info.getValue() || false}
                onChange={() => {
                  if (info.getValue()) {
                    onSelectStudent(info.row.original._id, 'evacuated');
                    setShowUndoModal(true);
                  } else {
                    onClickEvacuated(info.row.original);
                  }
                }}
              />
            </PermissionWrapper>
          </div>
        );
      }
    }),
    columnHelper.accessor('_id', {
      header: () => (
        <span style={{ display: 'block', textAlign: 'center' }}>Action</span>
      ),
      cell: (info) => {
        return (
          <div className="buttonGroups">
            <ActionPopper
              onViewStudent={() => handleViewStudent(info.getValue())}
            />
          </div>
        );
      }
    })
  ];

  const onSelectStudent = (id: string, action: string) => {
    setSelectedStudentId(id);
    setAction(action);
  };

  const handleViewStudent = (id: string) => {
    navigate(`/students/${id}`);
  };
  const fetchStudents = useCallback(async () => {
    try {
      const { data } = await getStudentsByRollCall(_id);
      setStudents(
        data.data.data.map((item: any) => {
          return {
            ...item,
            ...item.student,
            createdAt: item.createdAt
          };
        })
      );
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message || 'Failed to get student list'
      );
    }
    // eslint-disable-next-line
  }, [_id]);
  const handleConfirmUndo = () => {
    switch (action) {
      case 'check-in':
        handleCheckIn(selectedStudentId);
        break;
      case 'attended':
        handleAttended(selectedStudentId);
        break;
      case 'evacuated':
        handleEvacuated(selectedStudentId);
        break;

      default:
        break;
    }
    setShowUndoModal(false);
  };
  return (
    <div>
      <UndoActionRollCall
        open={showUndoModal}
        action={action}
        onClose={() => setShowUndoModal(false)}
        onConfirm={handleConfirmUndo}
      />
      {/* header */}
      <div className="header">
        <div
          className="class-title"
          style={{ borderBottomColor: data.class.levelBreakdown?.colorCode }}
        >
          {`${formatData(data.class?.name)} - AREA ${formatData(
            data.class.area
          )}`}
        </div>
      </div>
      <div className="content">
        <div className="header-table">Students</div>
        <PermissionWrapper permission={PERMISSION.LIST_STUDENT_ROLL_CALL}>
          <div className="content-table">
            <AppTable
              columns={isEmergency ? emergencyColumn : columns}
              data={students}
            />
          </div>
        </PermissionWrapper>
      </div>
    </div>
  );
};

export default RollCallForClassSession;
