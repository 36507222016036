import React, { useCallback, useEffect, useState } from 'react';
import AppCard from 'common/components/AppCard';
import AppBreadCrumb from 'components/common/AppBreadcrumb';
import AppTable from 'common/components/AppTable';
import AppCheckbox from 'common/components/AppCheckbox';
import { useNavigate } from 'react-router-dom';
import { createColumnHelper } from '@tanstack/react-table';
import { useBrandLocation } from 'context/BrandLocationContext';
import { GoChecklist } from 'react-icons/go';
// import { HiOutlinePrinter } from 'react-icons/hi';
import { IRollCall } from 'common/interfaces/rollCall.interface';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import weekday from 'dayjs/plugin/weekday';
import {
  getGroupRollCall,
  rollCallEmergency
} from 'services/rollCalls.service';
import AppButton from 'common/components/AppButton';
import { useToast } from 'context/ToastContext';
import { ROLL_CALL_TYPE } from 'common/enums/rollCall.enum';
import PermissionWrapper from 'components/PermissionWrapper';
import { PERMISSION } from 'common/enums/permission.enum';
import { formatData } from 'common/helpers/dataFormat.helper';

import './desktop.scss';

dayjs.extend(customParseFormat);
dayjs.extend(weekday);

const RollCalls: React.FC = () => {
  const navigate = useNavigate();
  const { selectedLocation: globalLocation } = useBrandLocation();
  const toast = useToast();

  const columnHelper = createColumnHelper<IRollCall>();
  const [selectedRollCallIds, setSelectedRollCallIds] = useState<Array<string>>(
    []
  );
  const [loading, setLoading] = useState<boolean>(true);

  const [data, setData] = useState<Array<IRollCall>>([]);
  const [pageIndex, setPageIndex] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [pageTotal, setPageTotal] = useState<number>(-1);

  const renderStyle = useCallback(
    (rollCall: IRollCall) => {
      if (rollCall.rollCallIds.every((i) => selectedRollCallIds.includes(i))) {
        return { fontWeight: '700' };
      }
      return { fontWeight: '400' };
    },
    [selectedRollCallIds]
  );

  const columns = [
    columnHelper.accessor('_id.startTime', {
      header: () => <span style={{ paddingLeft: '50px' }}>DATE</span>,
      cell: (info) => {
        const twoFirstRollCall: number[] = [];
        if (data.length) {
          let index = 0;
          while (twoFirstRollCall.length < 2 && index <= data.length - 1) {
            if (data[index]?._id.type === ROLL_CALL_TYPE.REGULAR) {
              twoFirstRollCall.push(index);
            }
            index++;
          }
        }
        const value = dayjs(info.getValue()).format('ddd D MMMM');
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ paddingLeft: '10px', width: '35px' }}>
              {pageIndex === 1 && twoFirstRollCall.includes(info.row.index) && (
                <AppCheckbox
                  onChange={() =>
                    onSelectRollCall(info.row.original.rollCallIds)
                  }
                  checked={info.row.original.rollCallIds.every((i) =>
                    selectedRollCallIds.includes(i)
                  )}
                />
              )}
            </div>
            <span
              style={{
                marginLeft: '10px',
                fontWeight: info.row.original.rollCallIds.every((i) =>
                  selectedRollCallIds.includes(i)
                )
                  ? '700'
                  : '400'
              }}
            >
              {value}
            </span>
          </div>
        );
      }
    }),
    columnHelper.accessor('_id.startTime', {
      header: () => <span>CLASS TIME</span>,
      cell: (info) => {
        return (
          <div style={renderStyle(info.row.original)}>{`${dayjs(
            info.getValue()
          ).format('HH:mm')} to ${dayjs(info.row.original._id.endTime).format(
            'HH:mm'
          )}`}</div>
        );
      }
    }),
    columnHelper.accessor('_id.type', {
      header: () => <span>TYPE</span>,
      cell: (info) => (
        <div style={renderStyle(info.row.original)}>
          {formatData(info.getValue())}
        </div>
      )
    }),
    columnHelper.accessor('_id.startTime', {
      header: () => <span>ROLL CALL TIME</span>,
      cell: (info) => (
        <div style={renderStyle(info.row.original)}>
          {dayjs(info.getValue()).format('HH:mm')}
        </div>
      )
    }),
    columnHelper.accessor('nearestCheckIn.updatedAt', {
      header: () => <span>Updated Time</span>,
      cell: (info) => {
        const value = dayjs(info.getValue()).format('DD/MM/YYYY HH:mm');
        return <div style={renderStyle(info.row.original)}>{value}</div>;
      }
    }),
    columnHelper.accessor('rollCallIds', {
      header: () => (
        <span
          style={{
            display: 'block',
            textAlign: 'center'
          }}
        >
          Action
        </span>
      ),
      cell: (info) => {
        return (
          <div className="buttonGroupsContainer">
            <div className="buttonGroups">
              <PermissionWrapper permission={PERMISSION.DETAIL_GROUP_ROLL_CALL}>
                <div
                  onClick={() =>
                    navigate(`/roll-calls/${info.getValue()?.join(',')}`)
                  }
                >
                  <GoChecklist />
                </div>
                {/* <div>
                  <HiOutlinePrinter size="20px" />
                </div> */}
              </PermissionWrapper>
            </div>
          </div>
        );
      }
    })
  ];

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      if (!globalLocation?._id) return;
      const { data } = await getGroupRollCall(
        pageIndex,
        pageSize,
        globalLocation?._id
      );
      const rollCalls = data.data.data;
      setData(rollCalls);
      setPageTotal(data.data.total);
    } catch (error) {
      setData([]);
      setPageTotal(-1);
    } finally {
      setLoading(false);
    }
  }, [globalLocation?._id, pageIndex, pageSize]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    setPageIndex(1);
  }, [globalLocation?._id]);

  const onSelectRollCall = (ids: string[]) => {
    if (ids.every((id) => selectedRollCallIds.includes(id))) {
      setSelectedRollCallIds(
        selectedRollCallIds.filter((i) => !ids.includes(i))
      );
    } else {
      setSelectedRollCallIds([...selectedRollCallIds, ...ids]);
    }
  };
  const createEmergencyRollCall = useCallback(async () => {
    if (!selectedRollCallIds.length) {
      toast.error('Please select the roll call');
      return;
    }
    try {
      const { data } = await rollCallEmergency({
        ids: selectedRollCallIds
      });
      const rollCallIds = [] as Array<string>;
      data.data.forEach((item: IRollCall) => {
        rollCallIds.push(...item.rollCallIds);
      });
      navigate(
        `/roll-calls/${rollCallIds.join(',')}?type=${ROLL_CALL_TYPE.EMERGENCY}`
      );
    } catch (error: any) {
      toast.error(error.response.data.message || 'Create Emergency failed');
    }
    // eslint-disable-next-line
  }, [selectedRollCallIds]);

  return (
    <main className="rollCallPage">
      <AppBreadCrumb items={[{ name: 'Roll Calls', href: '/roll-calls' }]} />
      <div className="layoutContainer rollCall">
        <PermissionWrapper permission={PERMISSION.LIST_GROUP_ROLL_CALL}>
          <div className="rollCall__table-content">
            <AppCard>
              <div className="header">
                <h3>ROLL CALLS</h3>
                <PermissionWrapper
                  permission={PERMISSION.CREATE_EMERGENCY_ROLL_CALL}
                >
                  <AppButton
                    buttonSize="small"
                    onClick={createEmergencyRollCall}
                  >
                    Emergency roll call
                  </AppButton>
                </PermissionWrapper>
              </div>
              <AppTable
                data={data}
                columns={columns}
                pagination={{
                  index: pageIndex,
                  size: pageSize,
                  total: pageTotal
                }}
                onChangePage={(index: number, size: number) => {
                  setPageIndex(index);
                  setPageSize(size);
                }}
                loading={loading}
              />
            </AppCard>
          </div>
        </PermissionWrapper>
      </div>
    </main>
  );
};

export default RollCalls;
