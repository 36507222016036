import {
  CreateHealthQuestionnaireDto,
  UpdateHealthQuestionnaireDto
} from 'DTOs/healthQuestionnaire.dto';
import API from './API';

const HEALTH_QUESTIONNAIRE_ENDPOINT = `/health-questionnaires`;

export const getHealthQuestionnairesAll = (locationId: string) => {
  return API.get(`${HEALTH_QUESTIONNAIRE_ENDPOINT}/find-all`, {
    locationId
  });
};

export const getHealthQuestionnaires = (
  locationId: string,
  page: number,
  limit: number,
  keyword: string
) => {
  return API.get(`${HEALTH_QUESTIONNAIRE_ENDPOINT}`, {
    locationId,
    page,
    limit,
    keyword
  });
};

export const getHealthQuestionnaireById = (id: string) => {
  return API.get(`${HEALTH_QUESTIONNAIRE_ENDPOINT}/${id}`);
};

export const createHealthQuestionnaire = (
  payload: CreateHealthQuestionnaireDto
) => {
  return API.post(`${HEALTH_QUESTIONNAIRE_ENDPOINT}`, {
    body: payload
  });
};

export const updateHealthQuestionnaire = (
  id: string,
  payload: UpdateHealthQuestionnaireDto
) => {
  return API.patch(`${HEALTH_QUESTIONNAIRE_ENDPOINT}/${id}`, {
    body: payload
  });
};

export const deleteHealthQuestionnaire = (id: string) => {
  return API.delete(`${HEALTH_QUESTIONNAIRE_ENDPOINT}/${id}`);
};
