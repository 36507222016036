import { useMemo, useState, useEffect, useCallback, ReactNode } from 'react';
import { useParams } from 'react-router-dom';
import dayjs, { Dayjs } from 'dayjs';
import { useToast } from 'context/ToastContext';
import { Resolver, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  HiOutlinePencilSquare,
  HiOutlineXCircle,
  HiOutlineBookmark
} from 'react-icons/hi2';
import { BeatLoader } from 'react-spinners';
import AppCard, {
  AppCardContent,
  AppCardContentItem,
  AppCardHeader
} from 'common/components/AppCard';
import AppDatePicker from 'common/components/AppDatePicker';
import AppInput from 'common/components/AppInput';
import AppLoadingContainer from 'common/components/AppLoadingContainer';
import AppGenderInput from 'common/components/AppGenderInput';
import AppLocationInput from 'components/common/AppLocationInput';
import { useBrandLocation } from 'context/BrandLocationContext';
import yup from 'validators/student.validator';
import { IStudent } from 'common/interfaces/student.interface';
import { UpdateStudentPersonalInfoDto } from 'DTOs/student.dto';
import { convertToUnixTime } from 'common/helpers/time.helper';
import { formatData, formatDate } from 'common/helpers/dataFormat.helper';
import { objectContainObj } from 'common/helpers/object.helper';
import { updateStudentPersonalInfo } from 'services/students.service';
import PermissionWrapper from 'components/PermissionWrapper';
import { PERMISSION } from 'common/enums/permission.enum';
import { GENDER_VALUE } from 'common/enums/student.enum';

import './desktop.scss';

interface Props {
  data: IStudent | null;
  onUpdated: () => void;
}

const validationSchema = yup.OBJECT({
  firstName: yup.STUDENT_NAME,
  lastName: yup.STUDENT_SURNAME,
  aliasName: yup.STUDENT_ALIAS,
  dob: yup.STUDENT_DOB,
  gender: yup.STUDENT_GENDER
});

const PersonalInformation = ({ data, onUpdated }: Props) => {
  const toast = useToast();
  const { id } = useParams();
  const { locations: globalLocations } = useBrandLocation();

  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const onOpenEdit = useCallback(() => {
    setIsEdit(true);
  }, []);

  const onCloseEdit = useCallback(() => {
    setIsEdit(false);
  }, []);

  const {
    register,
    setValue,
    clearErrors,
    control,
    trigger,
    reset,
    formState: { errors },
    handleSubmit
  } = useForm<UpdateStudentPersonalInfoDto>({
    resolver: yupResolver(
      validationSchema
    ) as Resolver<UpdateStudentPersonalInfoDto>,
    defaultValues: {
      firstName: data?.firstName || '',
      lastName: data?.lastName || '',
      aliasName: data?.aliasName || '',
      gender: data?.gender || GENDER_VALUE.IDLE,
      // @ts-ignore
      dob: convertToUnixTime(data?.dob || '')
    }
  });

  const watchAllFields = useWatch({ control });

  const handleInitValue = useCallback(() => {
    clearErrors();

    reset({
      firstName: data?.firstName || '',
      lastName: data?.lastName || '',
      aliasName: data?.aliasName || '',
      gender: data?.gender || GENDER_VALUE.IDLE,
      // @ts-ignore
      dob: convertToUnixTime(data?.dob || '')
    });
  }, [reset, data, clearErrors]);

  useEffect(() => {
    if (!data?._id) return;

    handleInitValue();
  }, [data, handleInitValue]);

  useEffect(() => {
    if (isEdit) return;

    handleInitValue();
  }, [isEdit, handleInitValue]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (event.target.name === 'firstName') {
        setValue('aliasName', event.target.value);
      }

      // @ts-ignore
      setValue(event.target.name, event.target.value);

      // @ts-ignore
      trigger(event.target.name);
    },
    [setValue, trigger]
  );

  const handleChangeDOB = useCallback(
    (val: Dayjs | null) => {
      setValue('dob', convertToUnixTime(dayjs(val)?.format()));

      trigger('dob');
    },
    [setValue, trigger]
  );

  const handleChangeGender = useCallback(
    (val: string) => {
      setValue('gender', val as GENDER_VALUE);

      trigger('gender');
    },
    [setValue, trigger]
  );

  const onSubmit = useCallback(async () => {
    if (disabled) {
      trigger();

      return;
    }

    try {
      const submitData = { ...watchAllFields };

      if (!id || !data || !submitData) return;

      const mapInitData = {
        ...data,
        dob: convertToUnixTime(dayjs(data?.dob).format()),
        joiningDate: convertToUnixTime(data.additionalInfo.joiningDate)
      } as UpdateStudentPersonalInfoDto;

      const mapData = {
        ...submitData,
        dob: convertToUnixTime(dayjs(submitData?.dob).format()),
        joiningDate: convertToUnixTime(data.additionalInfo.joiningDate)
      } as UpdateStudentPersonalInfoDto;

      if (objectContainObj(mapInitData, mapData)) {
        onCloseEdit();

        setLoading(false);
        setDisabled(false);

        return;
      }

      setLoading(true);
      setDisabled(true);

      await updateStudentPersonalInfo(id, mapData);

      toast.success('Update student personal info successfully');
      onCloseEdit();

      onUpdated();

      setLoading(false);
      setDisabled(false);
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message || 'Update student personal info failed'
      );

      setLoading(false);
      setDisabled(false);
    }

    // eslint-disable-next-line
  }, [id, onUpdated, watchAllFields, data, trigger, disabled, onCloseEdit]);

  const __renderIcons = useMemo((): ReactNode => {
    if (isEdit) {
      return loading ? (
        <BeatLoader color="white" />
      ) : (
        <>
          <div className="icon" onClick={handleSubmit(onSubmit)}>
            <HiOutlineBookmark size={24} />
          </div>
          <div className="icon" onClick={onCloseEdit}>
            <HiOutlineXCircle size={24} />
          </div>
        </>
      );
    }
    return (
      <div className="icon" onClick={onOpenEdit}>
        <HiOutlinePencilSquare />
      </div>
    );
  }, [isEdit, onSubmit, loading, handleSubmit, onOpenEdit, onCloseEdit]);

  const __renderContent = useCallback((): ReactNode => {
    const locationData = [...globalLocations]
      .map((location) => {
        return {
          value: location._id,
          label: location.name
        };
      })
      ?.filter((location) => location.value === data?.locationId)?.[0];

    if (isEdit) {
      return (
        <>
          <div className="item">
            <AppInput
              {...register('firstName')}
              label="Name*"
              onChange={handleChange}
              message={{
                type: 'error',
                text: errors?.firstName?.message || ''
              }}
            />
          </div>
          <div className="item">
            <AppInput
              {...register('lastName')}
              label="Surname*"
              onChange={handleChange}
              message={{
                type: 'error',
                text: errors?.lastName?.message || ''
              }}
            />
          </div>
          <div className="item">
            <AppInput
              {...register('aliasName')}
              label="Alias"
              onChange={handleChange}
              message={{
                type: 'error',
                text: errors?.aliasName?.message || ''
              }}
            />
          </div>

          <div className="item">
            <AppDatePicker
              {...register('dob')}
              label="D.O.B*"
              value={watchAllFields?.dob ? dayjs(watchAllFields?.dob) : null}
              disableFuture
              onChange={handleChangeDOB}
              message={{
                type: 'error',
                text: errors?.dob?.message || ''
              }}
            />
          </div>
          <div className="item">
            <AppGenderInput
              {...register('gender')}
              searchable={false}
              defaultValue={watchAllFields?.gender}
              label="Gender*"
              onChangeGender={handleChangeGender}
              message={{
                type: 'error',
                text: errors?.gender?.message || ''
              }}
            />
          </div>

          <div className="item">
            <AppLocationInput
              label="Location*"
              disabled
              defaultValue={locationData?.value}
            />
          </div>
          <div className="item">
            <AppInput
              label="Member number*"
              disabled
              value={data?.memberId?.toString()}
            />
          </div>
        </>
      );
    }

    return (
      <>
        <div className="item padY">
          <AppCardContentItem
            subtitle="Name"
            title={formatData(data?.firstName)}
          />
        </div>
        <div className="item padY">
          <AppCardContentItem
            subtitle="Surname"
            title={formatData(data?.lastName)}
          />
        </div>
        <div className="item padY">
          <AppCardContentItem
            subtitle="Alias"
            title={formatData(data?.aliasName)}
          />
        </div>
        <div className="item padY">
          <AppCardContentItem subtitle="DOB" title={formatDate(data?.dob)} />
        </div>
        <div className="item padY">
          <AppCardContentItem
            subtitle="Gender"
            title={formatData(data?.gender)}
          />
        </div>
        <div className="item padY">
          <AppCardContentItem
            subtitle="Location"
            title={formatData(locationData?.label ?? '')}
          />
        </div>
        <div className="item">
          <AppCardContentItem
            subtitle="Member number"
            title={formatData(data?.memberId?.toString())}
          />
        </div>
      </>
    );
  }, [
    isEdit,
    data,
    errors,
    handleChange,
    register,
    globalLocations,
    watchAllFields,
    handleChangeDOB,
    handleChangeGender
  ]);

  if (loading) {
    return <AppLoadingContainer />;
  }

  return (
    <section className="personal-information">
      <AppCard>
        <AppCardContent className="card-content">
          <div className="personal-information-main-content">
            <AppCardHeader
              title="PERSONAL INFORMATION"
              suffix={
                <PermissionWrapper
                  permission={PERMISSION.UPDATE_STUDENT_PERSONAL_INFO}
                >
                  {__renderIcons}
                </PermissionWrapper>
              }
            />

            <div className="content">{__renderContent()}</div>
          </div>
        </AppCardContent>
      </AppCard>
    </section>
  );
};

export default PersonalInformation;
